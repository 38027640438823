import { lisaApi } from 'services/api/lisaApi'
import {
  LisaResponse,
  LisaResponseExtended,
  OCRQueue,
  OverallTransitionProgressReport,
  OverallTransitionProgressReportValue,
  ProgressType,
  ProjectId,
  Transition,
  TransitionId,
  TransitionItem,
  User,
  UserId
} from 'types'
import { sortByWeek } from 'utils/transitionReports'

export interface AllLisaTransitions {
  transitionId?: TransitionId
  projectId?: ProjectId
  transitionName?: string
  projectName?: string
}

export const transitionApi = lisaApi.injectEndpoints({
  endpoints: (builder) => ({
    getTransition: builder.query<Transition, TransitionId>({
      query: (transitionId) => ({
        url: `/Transition/${transitionId}`
      }),
      providesTags: ['Transition']
    }),
    getAllTransitions: builder.query<AllLisaTransitions[], UserId>({
      query: (userId) => ({
        url: 'Transition/MyTransitions',
        params: { userId }
      }),
      providesTags: ['Transitions']
    }),
    getOcrQueueReporting: builder.query<OCRQueue[], void>({
      query: () => ({
        url: 'Transition/Dashboard/ocrQueueReporting'
      })
    }),
    getRecentTransitions: builder.query<TransitionItem[], UserId>({
      query: (userId) => ({
        url: 'Transition/Recent',
        params: { userId }
      }),
      providesTags: ['Transitions']
    }),
    getMyTransitions: builder.query<TransitionItem[], UserId>({
      query: (userId) => ({
        url: `Transition/MyTransitions?userId=${userId}`
      }),
      providesTags: ['Transitions']
    }),
    getOverallProgressReport: builder.query<OverallTransitionProgressReport, TransitionId>({
      query: (transitionId) => ({
        url: 'Transition/overallProgressReports',
        params: { transitionId }
      }),
      transformResponse: (response: {
        overallProgressNotes: string
        overallProgressReports: OverallTransitionProgressReportValue[]
        progress: ProgressType
      }) => ({
        notes: response.overallProgressNotes,
        data: sortByWeek(response.overallProgressReports)
      }),
      providesTags: ['TransitionOverallProgressReport']
    }),
    updateReportProgress: builder.mutation<void, {
      transitionId: TransitionId
      progress: ProgressType
      week: number
      year: number
    }>({
      query: ({
        year,
        week,
        transitionId,
        progress
      }) => ({
        url: `Transition/updateReportProgress?transitionId=${transitionId}&progress=${progress}&year=${year}&week=${week}`,
        method: 'POST'
      })
      // invalidatesTags: ['TransitionOverallProgressReport'] // @todo Looks like this is not needed
    }),
    updateOverallProgressNotes: builder.mutation<LisaResponseExtended, {
      transitionId: TransitionId
      notes: string
    }>({
      query: ({
        notes,
        transitionId
      }) => ({
        url: 'Transition/updateOverallProgressNotes',
        method: 'POST',
        body: {
          overallProgressNotes: notes,
          transitionId
        }
      })
    }),
    createOrEditTransition: builder.mutation<Transition, Partial<Transition>>({
      query: (params) => ({
        url: `Transition/${params.transitionId ? 'update' : 'create'}`,
        method: params.transitionId ? 'PUT' : 'POST',
        body: params
      }),
      invalidatesTags: ['Transition', 'Project', 'Projects']
    }),
    updateTransitionTeam: builder.mutation<Transition, Partial<Transition>>({
      query: (params) => ({
        url: 'Transition/updateTeam',
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['Transition', 'TransitionTeam']
    }),
    archiveTransition: builder.mutation<LisaResponse, {
      projectId: ProjectId
      transitionId: TransitionId
      userId: UserId | null
    }>({
      query: (params) => ({
        url: 'Transition/Delete',
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['Project']
    }),
    getTransitionTeam: builder.query<User[], TransitionId>({
      query: (transitionId) => ({
        url: `/Transition/Team?transitionId=${transitionId}`
      }),
      providesTags: ['TransitionTeam']
    })
  })
})

export const {
  useUpdateTransitionTeamMutation,
  useGetTransitionQuery,
  useGetAllTransitionsQuery,
  useGetOcrQueueReportingQuery,
  useGetRecentTransitionsQuery,
  useGetMyTransitionsQuery,
  useGetOverallProgressReportQuery,
  useUpdateReportProgressMutation,
  useUpdateOverallProgressNotesMutation,
  useCreateOrEditTransitionMutation,
  useArchiveTransitionMutation,
  useGetTransitionTeamQuery
} = transitionApi
