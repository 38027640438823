import { lisaApi } from 'services/api/lisaApi'
import {
  A2KConditionOperator,
  A2KConditionType,
  A2KDocument,
  A2KLogicalOperator, BusinessStatus,
  DocumentId,
  LabelId,
  LisaResponseExtended, PaginatedResults, SortDirection,
  TransitionId
} from 'types'
import { A2KRuleDocumentsSortColumn } from 'redux/slices/a2kRulesSlice'

export interface A2KRuleDocument extends A2KDocument {
  fileName: string
  status: BusinessStatus
}

export interface RuleTreeNode {
  variable: {
    variableName: A2KConditionType
  }
  variableValue: {
    tokenValue: string
  }
  operator: {
    tokenValue: A2KConditionOperator
  }
}
export interface CompoundA2KRuleTree {
  left: A2KRuleNode
  right: A2KRuleNode
  logicalOperator: {
    tokenValue: A2KLogicalOperator
  }
}
export type A2KRuleNode = RuleTreeNode | CompoundA2KRuleTree
export interface RuleResponse {
  ruleId: number
  labelId: LabelId
  userFriendlyQuery: string
  isDefaultRule: boolean
  ruleTree: {
    compoundA2KRuleTree: A2KRuleNode
  }
}

export enum A2KRulePreviewType {
  List = 1,
  Excluded,
  Added,
  Skipped
}

export interface A2KRulePreviewResponse {
  addedDocsCount: number
  excludedDocsCount: number
  matchedDocsCount: number
  skippedDocsCount: number
  documentsAddedFromNewRule: PaginatedResults<A2KRuleDocument>
  documentsExcludedFromNewRule: PaginatedResults<A2KRuleDocument>
  documentsMatchedNewRules: PaginatedResults<A2KRuleDocument>
  documentsSkipList: PaginatedResults<A2KRuleDocument>
  previewType: A2KRulePreviewType
  newRules: string[]
}

export const labelRuleApi = lisaApi.injectEndpoints({
  endpoints: (builder) => ({
    getRules: builder.query<RuleResponse[], LabelId>({
      query: (labelId) => ({
        url: `/LabelRule/rules/a2klabel/${labelId}`
      }),
      providesTags: ['Rule']
    }),
    saveRule: builder.mutation<LisaResponseExtended, {
      transitionId: TransitionId
      labelId: LabelId
      userFriendlyQuery: string
      isDefaultRule?: boolean
      ruleId?: number
      excludedListIgnoredDocs: DocumentId[]
    }>({
      query: ({ labelId, userFriendlyQuery, isDefaultRule = false, ruleId, transitionId, excludedListIgnoredDocs }) => ({
        url: 'LabelRule/ruleEngine/saveRule',
        method: 'POST',
        body: { labelId, userFriendlyQuery, isDefaultRule, ruleId, transitionId, excludedListIgnoredDocs }
      }),
      invalidatesTags: ['Rule', 'A2KLabels']
    }),
    previewRule: builder.mutation<A2KRulePreviewResponse, {
      transitionId: TransitionId
      userFriendlyQuery: string
      oldUserFriendlyQuery: string
      labelId: LabelId
      sortOrder: SortDirection
      sortColumn: A2KRuleDocumentsSortColumn
      previewType: A2KRulePreviewType
      pageSize: number
      page: number
    }>({
      query: ({ transitionId, userFriendlyQuery, oldUserFriendlyQuery, sortOrder, sortColumn, previewType, page, pageSize, labelId }) => ({
        url: 'LabelRule/ruleEngine/preview',
        method: 'POST',
        body: {
          transitionId,
          sortOrder,
          sortColumn,
          previewType,
          page,
          pageSize,
          newRules: [userFriendlyQuery],
          oldRules: oldUserFriendlyQuery ? [oldUserFriendlyQuery] : [],
          labelId
        }
      })
    })
  })
})

export const { useGetRulesQuery, useSaveRuleMutation, usePreviewRuleMutation } = labelRuleApi
