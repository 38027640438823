import { FC } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { useDispatch, useSelector } from 'react-redux'
import { sortByCompany, sortByEmail, sortByName, useUsers } from 'redux/slices/usersSlice'

export const SortByName: FC = () => {
  const { userLastName } = useSelector(useUsers)
  const dispatch = useDispatch()
  return (
    <TableCell
      align={'left'}
      style={{ paddingLeft: 16 }}
      width={'24%'}
      sortDirection={userLastName}>
      <TableSortLabel
        active={userLastName !== false}
        direction={userLastName || 'asc'}
        onClick={() => dispatch(sortByName())}>
        NAME
      </TableSortLabel>
    </TableCell>
  )
}

export const SortByEmail: FC = () => {
  const { email } = useSelector(useUsers)
  const dispatch = useDispatch()
  return (
    <TableCell
      align={'left'}
      padding={'none'}
      width={'25%'}
      sortDirection={email}>
      <TableSortLabel
        active={email !== false}
        direction={email || 'asc'}
        onClick={() => dispatch(sortByEmail())}>
        EMAIL ADDRESS
      </TableSortLabel>
    </TableCell>
  )
}

export const SortByRoles: FC = () => {
  const { roles } = useSelector(useUsers)
  // const dispatch = useDispatch()
  return (
    <TableCell
      align={'left'}
      padding={'none'}
      sortDirection={roles}>
      <TableSortLabel
        hideSortIcon={true}
        // active={roles !== false}
        active={false}
        direction={roles || 'asc'}
        // onClick={() => dispatch(sortByRoles())}>
        onClick={() => null}>
        ROLES
      </TableSortLabel>
    </TableCell>
  )
}

export const SortByCompany: FC = () => {
  const { company } = useSelector(useUsers)
  const dispatch = useDispatch()
  return (
    <TableCell
      align={'left'}
      padding={'none'}
      width={'10%'}
      sortDirection={company}>
      <TableSortLabel
        active={company !== false}
        direction={company || 'asc'}
        onClick={() => dispatch(sortByCompany())}>
        COMPANY
      </TableSortLabel>
    </TableCell>
  )
}
