import { FC } from 'react'
import { UpdateStructure } from './UpdateStructure'
import { UpdateSingleDocument } from './UpdateSingleDocument'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { sortBy, SortColumnToConfigMap, useImportDocuments } from 'redux/slices/importDocumentsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { CloneToA2KForm } from './CloneToA2KForm'
import { useSetBulkDocumentPriorityMutation } from 'services/api/documentApi'
import { useCancelImportMutation } from 'services/api/importApi'
import { useTransitionId } from 'context/TransitionContext'
import { ColumnConfigurator } from 'common/ColumnConfigurator/ColumnConfigurator'
import {
  ColumnConfiguratorKey,
  getCheckedColumnsFromCheckedColumnsSettings,
  importColumns,
  mandatoryColumns
} from 'utils/columnConfigurator'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useUpdateUserSettingsMutation } from 'services/api/usersApi'
import { User } from 'types'
import { closeModal } from 'redux/slices/appSlice'

const useStyles = makeStyles(() => ({
  message: {
    fontSize: '20px',
    padding: '48px 0'
  }
}))

export const ImportModals: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const transitionId = useTransitionId()
  const { selectedDocuments, filters } = useSelector(useImportDocuments)
  const { user: { userId, settings } } = useLisaAuth()
  const [setBulkDocumentPriority] = useSetBulkDocumentPriorityMutation()
  const [cancelImport] = useCancelImportMutation()
  const [updateUserSettings] = useUpdateUserSettingsMutation()
  return (
    <>
      <ConfirmDialog
        title='PRIORITISE OCR'
        type={'SET_HIGH_OCR_PRIORITY'}
        onConfirm={() => setBulkDocumentPriority(selectedDocuments.map(_ => _.documentId))}>
        <div className={classes.message}>Are you sure you want to prioritise OCR for selected documents?</div>
      </ConfirmDialog>
      <ConfirmDialog
        title='END IMPORT PROCESS'
        type={'END_IMPORT_PROCESS'}
        onConfirm={async () => {
          await cancelImport(transitionId)
        }}>
        <div className={classes.message}>Are you sure you want to end current import?</div>
      </ConfirmDialog>
      <LisaModal
        modalType={'ADD_FROM_ONE_DRIVE'}
        title="Add from onedrive"
        maxWidth="md"
        scroll='body'>
        <UpdateStructure/>
      </LisaModal>
      <LisaModal
        modalType={'UPLOAD_FILES'}
        title="Upload files"
        scroll='body'
        maxWidth="sm">
        <UpdateSingleDocument/>
      </LisaModal>
      <LisaModal
        modalType={'CLONE_TO_A2K'}
        title={'Clone selected to a2k'}
        scroll={'body'}>
        <CloneToA2KForm/>
      </LisaModal>
      <ColumnConfigurator
        columns={importColumns}
        checkedColumns={getCheckedColumnsFromCheckedColumnsSettings(settings.importSettings)}
        mandatoryColumns={mandatoryColumns.get('import')}
        onSave={async (checkedColumns) => {
          const importSettings = Object.keys(settings.importSettings).reduce((is, ccKey) => {
            return {
              ...is,
              [ccKey]: checkedColumns.includes(ccKey as keyof User['settings']['importSettings'])
            }
          }, settings.importSettings)
          if (!checkedColumns.includes(SortColumnToConfigMap.get(filters.sortColumn) as ColumnConfiguratorKey)) {
            // Sort by name if currently selected column is turned off
            dispatch(sortBy('Name'))
          }
          updateUserSettings({
            userId,
            settings: {
              importSettings
            }
          })
          dispatch(closeModal())
        }}/>
    </>
  )
}
