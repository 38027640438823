import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { User } from 'oidc-client-ts'

const getUser = () => {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTHORITY!}:${process.env.REACT_APP_CLIENT_ID!}`)
  if (!oidcStorage) {
    return null
  }
  return User.fromStorageString(oidcStorage)
}

export const lisaApi = createApi({
  reducerPath: 'lisaApi',
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: async (headers) => {
      const user = getUser()
      if (user?.access_token) {
        headers.set('Authorization', `Bearer ${user?.access_token}`)
      }
      return headers
    }
  }),
  tagTypes: [
    'User', 'Users',
    'Transition', 'Transitions', 'TransitionOverallProgressReport', 'TransitionTeam',
    'Document', 'Documents', 'DocumentMetaData', 'DocumentRecentReferences', 'CheckParserStatus',
    'ImportStructure', 'ImportProgress', 'ExportProgress',
    'Labels', 'LabelsRecent', 'A2KLabels',
    'Project', 'Projects',
    'OCRReport',
    'Query', 'Queries', 'QueryComments', 'QueryAttachments',
    'TableView', 'ProcessingStatus', 'ProcessingReport', 'Rule',
    'Notifications', 'ActiveNotifications', 'UnseenNotifications',
    'Tenant', 'Tenants', 'DocumentUpdate'
  ],
  endpoints: () => ({})

})
