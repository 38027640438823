import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import UserSettingsMenu from './UserSettingsMenu'
import { Grid } from '@material-ui/core'
import { TableData } from 'common/TableData/TableData'
import { SortByCompany, SortByEmail, SortByName, SortByRoles } from './TableSorting'
import { Roles } from 'types'
import { setPage, useUsers } from 'redux/slices/usersSlice'
import { UserAvatar } from 'common/UserAvatar/UserAvatar'
import { WithPagination } from 'common/WithPagination/WithPagination'
import { useLisaUsers } from 'hooks/useLisaUsers'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white
  },
  dataTableCheckbox: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.35rem'
    }
  },
  bodyRows: {
    height: '56px !important',
    '& td': {
      borderBottom: `4px solid ${theme.palette.grey2.main}`,
      borderTop: `4px solid ${theme.palette.grey2.main}`
    }
  },
  pagination: {
    marginTop: '8px',
    marginBottom: '8px',
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
      background: theme.palette.blue.main,
      '&:hover': {
        background: theme.palette.blue.main
      }
    }
  },
  userCell: {
    padding: '4px 0 4px 16px',
    display: 'flex',
    alignItems: 'center'
  },
  tableText: {
    fontWeight: 'normal',
    fontSize: 16
  },
  userName: {
    fontWeight: 600,
    paddingLeft: 10,
    fontSize: 16,
    whiteSpace: 'break-spaces'
  },
  rolesCell: {
    lineHeight: 1.5,
    whiteSpace: 'break-spaces'
  }
}))

export const TableText: FC<{text?: string, strong?: boolean, roles?: Roles[]}> = ({ text, strong = false, roles }) => {
  const classes = useStyles()
  return (
    <div>
      {
        roles
          ? <div className={classes.rolesCell}>
            { roles.map((role, i: number) =>
              <span className={classes.tableText} key={`${role.name}-${i}`}>
                {`${role.name}${roles.length === i + 1 ? ' ' : ', '}`}
              </span>
            )
            }
          </div>
          : <Typography>
            <span className={clsx(classes.tableText,
              { [classes.userName]: strong })
            }>
              {text}
            </span>
          </Typography>
      }
    </div>
  )
}

// @todo Not great having two APIs for same results
export const UsersListTable: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { page } = useSelector(useUsers)
  const { users, isLoading, pageSize, pages, total } = useLisaUsers()

  return (
    <Grid container direction={'column'} className={classes.root}>
      <WithPagination
        options={{
          pageSize,
          total
        }}
        page={page}
        pages={pages}
        setPage={(value: number) => dispatch(setPage(value))}>
        <TableData
          noShadow
          noGrow
          loading={isLoading}
          loadingText='Loading Users'
          noResultsProps={{
            type: 'users',
            message: 'No users'
          }}
          headCells={[
            <SortByName key='userLastName' />,
            <SortByEmail key='email' />,
            <SortByRoles key='roles' />,
            <SortByCompany key='company' />,
            <TableCell key='userMenu' width={'5%'}/>
          ]}
          tableRows={
            users.map((row, i) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={`${row.userId}-${i}`}
                  className={classes.bodyRows}
                >
                  <TableCell
                    id={row.userId}
                    width={'24%'}>
                    <div className={classes.userCell}>
                      <UserAvatar user={{ ...row, url: row.profilePictureURL }} />
                      <TableText text={`${row.firstName} ${row.lastName}`} strong/>
                    </div>
                  </TableCell>
                  <TableCell align="left" width={'25%'}>
                    <TableText text={row.email} />
                  </TableCell>
                  <TableCell align="left">
                    <TableText roles={row.roles} />
                  </TableCell>
                  <TableCell align="left" width={'10%'} >
                    <TableText text={row.company} />
                  </TableCell>
                  <TableCell align='center' width={'5%'}>
                    <UserSettingsMenu _user={row}/>
                  </TableCell>
                </TableRow>
              )
            })
          }>
        </TableData>
      </WithPagination>
    </Grid>
  )
}
