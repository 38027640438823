import { FC, useEffect, useMemo } from 'react'
import { useLabelsContext } from 'context/LabelsContext'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { getAllLabelIds, toList } from 'utils/labels'
import { Label } from 'types'
import { toggleLabelsInTree } from 'redux/slices/a2kLabelsSlice'
import { useAutocompleteStyle } from 'hooks/styles'

type LabelSearchAutoCompleteProps = {
  onLabelsSelect?: (labels: Label[]) => void
}
export const LabelSearchAutoComplete: FC<LabelSearchAutoCompleteProps> = ({ onLabelsSelect = () => {} }) => {
  const acClasses = useAutocompleteStyle()
  const { selector, reducers: { search } } = useLabelsContext()
  const { searchTerm, searchResults, hierarchy } = useSelector(selector)
  const dispatch = useDispatch()

  const labelsList = useMemo<Label[]>(() => {
    if (hierarchy === null) {
      return []
    }
    return toList(hierarchy).slice().filter(_ => _.name !== 'Root').sort(
      (a, z) => a.name.toLowerCase().localeCompare(z.name.toLowerCase()))
  }, [hierarchy?.labelId, hierarchy])

  useEffect(() => {
    if (searchTerm) {
      dispatch(toggleLabelsInTree({
        labelIds: getAllLabelIds(searchResults, true),
        labelsState: searchTerm !== ''
      }))
    }
    onLabelsSelect(searchResults)
  }, [searchTerm])

  return (
    <Autocomplete
      className={acClasses.autocomplete}
      renderInput={(params) => (
        <TextField
          {...params}
          className={acClasses.textField}
          value={searchTerm}
          variant='outlined'/>
      )}
      options={labelsList}
      // renderOption={(label) => label.name}
      getOptionLabel={(label) => label.name ?? ''}
      freeSolo
      autoHighlight
      fullWidth
      value={searchResults[0] ?? null}
      onChange={(e, label) => {
        if (label !== null) {
          dispatch(search({ search: label }))
        }
      }}
      onInputChange={(e, value, reason) => {
        if (reason === 'clear') {
          dispatch(search({ search: null }))
        } else {
          // dispatch(search(value))
        }
      }}
    />
  )
}
