import { lisaApi } from 'services/api/lisaApi'
import {
  FileTree,
  Folder,
  ImportDocument,
  LisaResponseExtended,
  PaginatedResults,
  SortDirection,
  StorageId,
  TransitionId
} from 'types'
import { transitionApi } from 'services/api/transitionApi'

interface ImportProgress {
  importInProgres: boolean
  importedCount: number
  importedPercent: number
  totalCount: number
}

export const importApi = lisaApi.injectEndpoints({
  endpoints: (builder) => ({
    renameDocument: builder.mutation<LisaResponseExtended, {
      storageId: StorageId
      name: string
    }>({
      query: ({
        storageId,
        name
      }) => ({
        url: `Import/rename?id=${storageId}&name=${name}`,
        method: 'PUT'
      }),
      invalidatesTags: ['Document', 'Documents']
    }),
    getFilesFromFolder: builder.query<PaginatedResults<ImportDocument>, {
      id: string,
      unlabelled?: boolean,
      deleted?: boolean,
      page?: number,
      pageSize?: number,
      sortOrder?: SortDirection,
      sortColumn?: string,
    }>({
      query: ({
        id,
        unlabelled = false,
        deleted = false,
        page = 1,
        pageSize = 200,
        sortOrder = 'asc',
        sortColumn = 'Name'
      }) => ({
        url: 'Import/v2/files',
        params: {
          id,
          unlabelled,
          deleted,
          page,
          pageSize,
          sortOrder,
          sortColumn
        }
      }),
      providesTags: ['Documents']
    }),
    getImportFileTree: builder.query<FileTree, { transitionId: TransitionId, unlabelled: boolean }>({
      query: ({ transitionId, unlabelled }) => ({
        url: 'Import/structure',
        params: { transitionId, unlabelled }
      }),
      providesTags: ['ImportStructure'],
      transformResponse: (response: { structure: FileTree }) => response.structure
    }),
    getOneDriveStructure: builder.query<Folder[], void>({
      query: () => ({
        url: 'Import/childrenById'
      })
    }),
    checkImportProgress: builder.query<ImportProgress, TransitionId>({
      query: (transitionId) => ({
        url: 'Import/Progress',
        params: { transitionId }
      }),
      providesTags: ['ImportProgress'],
      onQueryStarted: async (transitionId, { dispatch, queryFulfilled }) => {
        const { data: importProgress } = await queryFulfilled
        if (!importProgress.importInProgres) {
          dispatch(transitionApi.util.updateQueryData('getTransition', transitionId, (transition) => {
            Object.assign(transition, { importInProgress: false })
          }))
        }
      }
    }),
    startImportFromOneDrive: builder.mutation<LisaResponseExtended, {
      transitionId: TransitionId
      path: string
    }>({
      query: (params) => ({
        url: 'Import/Init',
        method: 'POST',
        body: params
      }),
      // invalidatesTags: ['ImportProgress'], // @todo Import progress has lags. So we will have to use onQueryStarted instead
      onQueryStarted: async ({ transitionId }, {
        dispatch,
        queryFulfilled
      }) => {
        const { data: { success } } = await queryFulfilled
        if (success) {
          dispatch(transitionApi.util.updateQueryData('getTransition', transitionId, (transition) => {
            Object.assign(transition, { importInProgress: true })
          }))
          dispatch(importApi.util.updateQueryData('checkImportProgress', transitionId, (draft) => {
            Object.assign(draft, {
              importInProgres: true,
              importedCount: 0,
              importedPercent: 0,
              totalCount: 0
            })
          }))
        }
      }
    }),
    cancelImport: builder.mutation<LisaResponseExtended, TransitionId>({
      query: (transitionId) => ({
        url: `Import/Cancel?transitionId=${transitionId}`,
        method: 'PUT'
      }),
      invalidatesTags: ['ImportProgress']
    })
  })
})

export const {
  useRenameDocumentMutation,
  useGetFilesFromFolderQuery,
  useGetImportFileTreeQuery,
  useGetOneDriveStructureQuery,
  useCheckImportProgressQuery,
  useStartImportFromOneDriveMutation,
  useCancelImportMutation
} = importApi
