import { ChangeEvent, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { TransitionForm } from 'pages/Administration/components/projects/TransitionForm'
import { useTransitionContext, useTransitionId } from 'context/TransitionContext'
import { useGetTransitionQuery } from 'services/api/transitionApi'

const LisaStructureTabs = () => {
  const { hasAccess } = useLisaAuth()
  const { params } = useRouteMatch()
  const { data: transition, isLoading } = useGetTransitionQuery(useTransitionId())
  const { transitionId } = useTransitionContext()
  const history = useHistory()
  const currentUrlParam = Object.values(params)[0]

  const handleChange = (e: ChangeEvent<{}>, page: string) => {
    if (page !== 'settings') {
      history.push(page)
    }
  }

  const tabValue = (uri: string) => {
    const isSubUrl = (currentUrlParam === 'team' || currentUrlParam === 'transition') && uri === 'settings'
    const subUrl = isSubUrl ? `${currentUrlParam}/` : ''
    return `/structures/${uri}/${subUrl}${transitionId}`
  }

  const path = useMemo(() => {
    if (history.location.pathname.indexOf('/structures/settings') === 0) {
      return tabValue('settings')
    }
    return history.location.pathname
  }, [history.location.pathname])

  return (
    <>
      <Tabs
        value={path}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        {hasAccess('perm_view_impstructure') &&
          <Tab label="Import" className={'importTab'} value={tabValue('import')}/>}
        {hasAccess('perm_view_a2kstructure') && <Tab label="A2K" className={'a2kTab'} value={tabValue('atok')}/>}
        {hasAccess('perm_view_clientstructure') &&
          <Tab label="Client" className={'clientTab'} value={tabValue('client')}/>}
        {hasAccess('perm_view_query') && <Tab label="Queries" value={tabValue('queries')}/>}
        {hasAccess('perm_view_areports') && <Tab label="Reports" value={tabValue('reports')}/>}
        {
          hasAccess('perm_act_transitionsettings') &&
          <Tab
            label="Settings"
            value={tabValue('settings')}/>
        }
      </Tabs>
      {
        !isLoading &&
        <LisaModal
          title={'Edit Asset'}
          scroll={'body'}
          modalType={'TRANSITION_FORM'}>
          <TransitionForm
            projectId={transition!.projectId}
            transition={transition!}
          />
        </LisaModal>
      }
    </>
  )
}

export default LisaStructureTabs
