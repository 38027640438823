import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Grid, makeStyles } from '@material-ui/core'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { UserForm } from './components/users/UserForm'
import { UsersListTable } from './components/users/UsersList'
import {
  resetSortOptions,
  setSearchTerm
} from 'redux/slices/usersSlice'
import { GridGrow } from 'common/Grid/GridGrow'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { openModal } from 'redux/slices/appSlice'
import InputSearch from 'common/LisaControls/InputSearch'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: '16px 16px 32px 16px',
    '& .MuiGrid-container': {
      '&:first-child': {}
    },
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiGrid-item': {
    }
  },
  paper: {
    borderRadius: '4px',
    margin: '0px 10px 0px 0px',
    marginBottom: '0px'
  },
  searchWrapper: {
    marginBottom: 8
  },
  search: {
    marginBottom: '10px',
    '& .fas.fa-info-circle': {
      display: 'none'
    }
  },
  inviteButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 10
  },
  loader: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 200px)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '2px',
    margin: '4px'
  },
  noDocuments: {
    display: 'flex',
    margin: '5px',
    background: 'rgba(255,255,255,.4)',
    height: 'calc(100vh - 200px)',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.palette.black.light100
  },
  noDocumentIco: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      width: '52px',
      height: '52px',
      marginBottom: '24px',
      fill: theme.palette.black.light100
    }
  }
}))

export const Users: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const { userId } = useLisaAuth()

  useEffect(() => {
    return () => {
      dispatch(resetSortOptions())
    }
  }, [])

  const handleValue = (event: ChangeEvent<{ value: string }>) => {
    event.preventDefault()
    const { value } = event.target
    setSearchText(value)
  }

  const handleClickAll = () => {
    if (userId) {
      dispatch(setSearchTerm(''))
    }
  }

  const enterPressed = (event: KeyboardEvent) => {
    event.preventDefault()
    if (searchText && searchText.length > 2 && event.key === 'Enter' && userId) {
      dispatch(setSearchTerm(searchText))
    }
    if ((event.key === 'Backspace' || event.key === 'Delete') && searchText.length === 0) handleClickAll()
  }

  return (
    <ContentWrapper newLayout>
      <Grid container spacing={0} className={classes.searchWrapper}>
        <Grid item xs={8} lg={4} className={classes.search}>
          <InputSearch
            onChange={handleValue}
            label='Find users'
            onClick={(e) => {
              e.preventDefault()
              if (userId) {
                dispatch(setSearchTerm(searchText))
              }
            }}
            value={searchText}
            onKeyUp={enterPressed}
          />
        </Grid>
        <Grid item xs={4} lg={8} className={classes.inviteButton}>
          <Button
            variant='contained'
            color='primary'
            size='large'
            onClick={() => dispatch(openModal('INVITE_NEW_USER'))}
          >
              INVITE USER
          </Button>
        </Grid>
      </Grid>
      <GridGrow container direction={'column'}>
        <Grid item>
          <UsersListTable />
        </Grid>
      </GridGrow>
      <LisaModal
        modalType={'INVITE_NEW_USER'}
        title={'INVITE USER'}
        scroll='body'>
        <UserForm />
      </LisaModal>
    </ContentWrapper>
  )
}

export default Users
